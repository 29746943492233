:root {
    --black: #333;
    --white: #fff;
    --gray: #aaa;
    --transition: 0.4s ease;
}

textarea {
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    resize: none;
}

textarea::placeholder {
    color: var(--gray);
}

.commentLable {
    height: 0;
    width: 0;
    visibility: hidden;
}

.comment-box {
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
    margin: 14px auto;
    background: var(--white);
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 11px;
    transition: min-height var(--transition);
    max-height: 46px;
}

.header {
    transition: opacity var(--transition) 0.2s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-40px);
}

.user {
    display: flex;
    align-items: center;
}

.user img {
    border-radius: 20px;
    margin-right: 10px;
}

.comment-field {
    color: var(--black);
    transition: transform var(--transition);
    font-size: 18px;
    width: 100%;
    outline: none;
    padding: 0;
    min-height: 60px;
    transform: translateY(-32px);
    line-height: 1;
}

.comment-box.expanded .comment-field {
    transform: translateY(40px);
}

.comment-box.expanded .header {
    transform: translateY(10px);
    opacity: 1;
    visibility: visible;
}

.actions {
    width: 100%;
    display: flex;
    /* align-self: flex-end; */
    /* justify-content: space-between; */
    margin-top: 50px;
    opacity: 0;
    transition: opacity var(--transition) 0.2s;
}

.comment-box.expanded .actions {
    opacity: 1;
}

.comment-box.modified {
    max-height: unset;
}