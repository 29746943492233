:root {
    --space: 30px;
  }
  
  body {
    background: #f7f6f6;
    padding: 1px;
  }
  
  .stepper {
    display: flex;
    margin: 10px;
    padding: 0;
    list-style: none;
    position: relative;
    
  }
  
  .stepper::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    content: "";
    width: calc(100% - var(--space));
    /* background: linear-gradient(90deg, #317F9F 0%, #317F9F 73%, lightgrey 73%, lightgrey 100%); */
  }
  

  .stepper__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    flex: 100%;
    padding: 5px 23px 5px 31px;
    background: #b0cce552;
    margin: 0 0 0 calc(calc(-1 * var(--space)) + 1px);
    -webkit-clip-path: polygon(var(--space) 50%, 0% 0%, calc(100% - var(--space)) 0%, 100% 50%, calc(100% - var(--space)) 100%, 0% 100%);
    clip-path: polygon(var(--space) 50%, 0% 0%, calc(100% - var(--space)) 0%, 100% 50%, calc(100% - var(--space)) 100%, 0% 100%);
    border-top: 1px solid rgb(255, 253, 253);
    border-bottom: 1px solid lightgrey;
    margin-right: 5px;
    position: relative;
  }
  
  .stepper__item.current {
    background: #00a74a;
    font-weight: bold;
    color: white;
    border-top: 1px solid #00a74a;
    border-bottom: 1px solid #00a74a;
  }
  
  .stepper__item.complete {
    /* background-color: 'linear-gradient( 296deg , #00a74a, #15489f)';
    border-top: 1px solid #317F9F;
    border-bottom: 1px solid #317F9F; */
    background:  #15489f;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: white;
  }
  
  .stepper__item.complete:hover {
    background:linear-gradient( 296deg , #00a74a, #15489f);
    color: white;
  }
  
  .stepper__item:first-child {
    /* -webkit-clip-path: polygon(0% 0%, calc(100% - var(--space)) 0%, 100% 50%, calc(100% - var(--space)) 100%, 0% 100%); */
    -webkit-clip-path: polygon(var(--space) 50%, 0% 0%, calc(100% - var(--space)) 0%, 100% 50%, calc(100% - var(--space)) 100%, 0% 100%);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 1px solid #317F9F;
  }
  
  .stepper__item:last-child {
    /* -webkit-clip-path: polygon(var(--space) 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%); */
    -webkit-clip-path: polygon(var(--space) 50%, 0% 0%, calc(100% - var(--space)) 0%, 100% 50%, calc(100% - var(--space)) 100%, 0% 100%);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: 1px solid lightgrey;
  }
  